<template>
  <div class="mobile-menu">
    <ul>
      <li>
        <a href="/" v-on:click="toggleFurniture">
          <img src="../assets/furniture-thumb.jpg" alt="">
          <span>Our Furniture</span>
        </a>
      </li>
      <li>
        <a href="../carrarino-brochure.pdf" target="_blank">
          <img src="../assets/brochure-thumb.jpg" alt="">
          <span>Our Brochure<br />Download</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'mobileMenu',
  props: {
    furntitureOpen: Boolean
  },
  methods: {
    toggleFurniture (e) {
      e.preventDefault()
      this.$emit('toggleFurniture')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../base.scss";

  .mobile-menu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-darkblue-opacity;

    @include lrg {
      width: 35%;
      top: 1%;
      right: 1%;
      height: 60%;
      max-height: 400px;
      max-width: 400px;
    }

    ul {
      @include flex;
      @include flex-wrap;
      @include flex-justify-center;
      @include flex-align-center;
      width: 100%;
      height: 100%;

      @include med {
        height: 60%;
        margin-top: 10%;
      }

      @include lrg {
        height: 80%;
        margin-top: 7%;
      }

      li {
        min-width: 51%;

        a  {
          @include flex;
          @include flex-justify-center;
          @include flex-align-center;
          text-decoration: none;
          color: $color-lightblue;
          font-size: 12px;

          @include sml {
            font-size: 14px;
          }

          @include med {
            font-size: 16px;
          }

          &:hover {
            color: #fff;
          }

          img {
            width: 75px;
          }

          span {
            padding-left: 20px;
          }
        }
      }
    }
  }

</style>
