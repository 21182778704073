<template>
  <div class="info-section">
    <p v-if="mobileScreen" class="info-section__title">Please note</p>
    <p v-if="mobileScreen" class="info-section__copy">Our new website is currently being designed, however, we are always happy to help.</p>
    <div>
      <div class="info-section__phonesvg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.6 25.6">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path d="M17.2,2,10.1,7.9H10L2.9,13.9c-6.7,5.6.3,11.7.3,11.7l6.9-5.7a8.5,8.5,0,0,0-3.4-4.7l5.5-4.6h.1l5.5-4.7a8.5,8.5,0,0,0,4,4.2l6.8-5.7S23.9-3.6,17.2,2Z" fill="#b9d0e7"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="info-section__phonenos">
        <a href="tel:01460394007">01460 394 007</a>
        <span class="bull">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="50" fill="#b6d1df" />
          </svg>
        </span>
        <a href="tel:07534970786">07534 970 786</a>
      </div>
      <div class="info-section__contact info-section__contact--address">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.5 18.5">
          <title>Address</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path d="M12.8,17.5a9,9,0,0,1-4.7,1A7.9,7.9,0,0,1,0,10.2C0,4.7,3.8,0,9.7,0a7.4,7.4,0,0,1,7.8,7.7c0,4-2.3,6.3-4.8,6.3a1.9,1.9,0,0,1-1.9-2.3h-.1A4.2,4.2,0,0,1,6.9,14,3,3,0,0,1,4,10.7a6.3,6.3,0,0,1,6.3-6.3,7.6,7.6,0,0,1,2.9.5l-.9,4.9c-.4,2-.1,2.9.8,2.9S16,11,16,7.9,13.6,1.2,9.5,1.2s-8,3.5-8,8.9,2.9,7.2,6.9,7.2a8.5,8.5,0,0,0,4-1ZM11.4,6,10,5.8C7.7,5.8,5.8,8,5.8,10.6s.6,2,1.7,2,3-2,3.3-3.6Z" fill="#b9d0e7"/>
            </g>
          </g>
        </svg>
        <p>Somerset TA13 5LP</p>
      </div>
      <div class="info-section__contact info-section__contact--email">
        <a href="mailto:office@carrarino.co.uk?subject=Web%20message">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.9 24.8">
            <title>Email</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <g>
                  <polyline points="0 21.9 15.6 12.2 0 2.1" fill="#b9d0e7"/>
                  <path d="M35.6,0H1.1A1.2,1.2,0,0,0,0,1.3L18.9,13.6,37.8,1.5S37.7,0,35.6,0Z" fill="#b9d0e7"/>
                  <path d="M35.6,24.8H1.8A1.9,1.9,0,0,1,.1,22.6H.2L16,12.7h.1L19,14.3l1.5-.9,1.2-.7,16.1,10h0A2,2,0,0,1,35.6,24.8Z" fill="#b9d0e7"/>
                  <polyline points="37.8 21.9 22.3 12.2 37.9 2.1" fill="#b9d0e7"/>
                </g>
              </g>
            </g>
          </svg>
          <p>office@carrarino.co.uk</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'infoSecton',
  props: {
    mobileScreen: Boolean
  }
}
</script>

<style scoped lang="scss">
  @import "../base.scss";

  .info-section {
    padding: 20px 10px;

    @include lrg {
      position: absolute;
      padding: 20px;
      top: 1%;
      right: 1%;
      background-color: $color-darkblue-opacity;
      height: 60%;
      width: 35%;
      padding: 0;
      @include flex;
      @include flex-justify-center;
      @include flex-align-center;
      max-height: 400px;
      max-width: 400px;
    }

    &__title {
      color: $color-lightblue;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
      text-align: center;
    }
    // @media (min-width: 700px) {
    //         font-size: 24px;
    //     }

    &__copy {
      font-size: 11px;
      font-weight: 300;
      text-align: center;
      width: 100%;
      max-width: 310px;
      line-height: 20px;
      margin: 0 auto;

      @include sml {
        font-size: 13px;
        max-width: 350px;
      }

      @include med {
        font-size: 15px;
        max-width: 400px;
      }

    }
    // @media (min-width: 700px) {
    //         font-size: 16px;
    //         width: 500px;
    //         line-height: 26px;
    //     }

    &__phonesvg {
      width: 25px;
      margin: 20px auto 10px;

      @include lrg {
        margin: 0 auto 10px;
      }
    }

    &__phonenos {
      text-align: center;

      a {
        text-decoration: none;
        color: #fff;
        font-size: 15px;

        &:hover {
          color: $color-lightblue;
        }
      }
      span.bull {
        margin: 0 8px;
        top: -2px;
        position: relative;

        svg {
          width: 5px;
        }
      }
    }

    &__contact {
      text-align: center;
      margin-top: 15px;

      svg {
        width: 20px;
        position: relative;
        margin-right: 10px;
      }

      &--address {
        margin-top: 25px;

        svg {
          top: 5px;
        }
      }

      &--email {
        svg {
          top: 1px;
        }
      }
      p {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        padding: 0;
        margin: 0;
      }
      a {
        color: #fff;

        &:hover {
          color: $color-lightblue;
        }

        p{
        }
      }
    }
  }
</style>
