<template>
  <div id="app">

    <div v-show="!videoPlaying && !furntitureOpen" class="page-container page-container--main-page">
      <header>
        <div class="right-block">
          <div v-if="!mobileScreen">
            <p class="info-section__title">Please note</p>
            <p class="info-section__copy">Our new website is currently being designed, however, we are always happy to help.</p>
          </div>
          <navMenu :menuOpen="menuOpen" v-on:toggleMenu="toggleMenu" v-on:toggleCallMenu="toggleCallMenu" :mobileScreen="mobileScreen" />
        </div>
        <div class="header-logo">
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.7 111.5">
        <title>Carrarino - Italian craftsmanship set in stone</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <g>
                        <path d="M0,69.5c0-9,6.3-15.2,14.9-15.2,4.5,0,8.6,1.7,11.1,5.1l-1.4,1.2a11.6,11.6,0,0,0-9.7-4.5c-7.4,0-13,5.4-13,13.4S7.5,83,14.9,83a11.7,11.7,0,0,0,9.7-4.6L26,79.6c-2.5,3.4-6.6,5.1-11.1,5.1C6.3,84.7,0,78.5,0,69.5Z" fill="#fff"/>
                        <path d="M59,65.1V84.5H57.2v-6c-1.9,3.8-5.7,6.2-11.2,6.2s-10.7-3.4-10.7-8.4S38,68,46.2,68H57.1V65.1c0-6-3.2-9.1-9.2-9.1a15.4,15.4,0,0,0-10.7,4l-1.1-1.3a17.2,17.2,0,0,1,11.8-4.4C55.1,54.3,59,58,59,65.1ZM57.1,75.9V69.6H46.2c-6.5,0-9,2.8-9,6.5s3.3,7,8.9,7S55.3,80.5,57.1,75.9Z" fill="#fff"/>
                        <path d="M88,54.3v1.9h-.5c-7,0-11.1,4.7-11.1,12.4V84.5h-2v-30h1.9v7.2C78,57,82.1,54.3,88,54.3Z" fill="#fff"/>
                        <path d="M112.8,54.3v1.9h-.5c-7,0-11.1,4.7-11.1,12.4V84.5H99.3v-30h1.8v7.2C102.9,57,106.9,54.3,112.8,54.3Z" fill="#fff"/>
                        <path d="M145,65.1V84.5h-1.8v-6c-1.9,3.8-5.7,6.2-11.2,6.2s-10.8-3.4-10.8-8.4,2.8-8.3,11-8.3h10.9V65.1c0-6-3.2-9.1-9.3-9.1a15.8,15.8,0,0,0-10.7,4l-1-1.3a17,17,0,0,1,11.8-4.4C141.1,54.3,145,58,145,65.1Zm-1.9,10.8V69.6H132.2c-6.6,0-9.1,2.8-9.1,6.5s3.4,7,9,7S141.2,80.5,143.1,75.9Z" fill="#fff"/>
                        <path d="M173.9,54.3v1.9h-.5c-7,0-11.1,4.7-11.1,12.4V84.5h-1.9v-30h1.9v7.2C164,57,168,54.3,173.9,54.3Z" fill="#fff"/>
                        <path d="M184.2,44.8a1.9,1.9,0,0,1,2-1.9,1.9,1.9,0,1,1,0,3.7A1.9,1.9,0,0,1,184.2,44.8Zm1,9.7h1.9v30h-1.9Z" fill="#fff"/>
                        <path d="M229.2,66.9V84.5h-1.9V67c0-7.2-3.9-10.9-10.4-10.9s-12.1,4.8-12.1,12.2V84.5h-1.9v-30h1.8v7.3c2-4.6,6.3-7.5,12.4-7.5S229.2,58.6,229.2,66.9Z" fill="#fff"/>
                        <path d="M241.2,69.5c0-8.9,6.3-15.2,14.8-15.2s14.7,6.3,14.7,15.2S264.5,84.7,256,84.7,241.2,78.4,241.2,69.5Zm27.6,0c0-8-5.5-13.4-12.8-13.4s-12.9,5.4-12.9,13.4S248.6,83,256,83,268.8,77.5,268.8,69.5Z" fill="#fff"/>
                    </g>
                    <g>
                        <path d="M20,103.8h.5v7.5H20Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M25.1,104.3H22.4v-.5h5.9v.5H25.6v7h-.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M34.5,109.2H30.1l-.9,2.1h-.6l3.4-7.5h.6l3.4,7.5h-.6Zm-.2-.5-2-4.3-2,4.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M37.9,103.8h.5v7h4.3v.5H37.9Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M44.7,103.8h.5v7.5h-.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M53,109.2H48.6l-.9,2.1h-.6l3.4-7.5h.6l3.4,7.5h-.6Zm-.2-.5-2-4.3-1.9,4.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M62.5,103.8v7.5h-.4L57,104.8v6.5h-.6v-7.5h.5l5.1,6.5v-6.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M68.2,107.5a3.8,3.8,0,0,1,3.9-3.8,3.5,3.5,0,0,1,2.6,1.1l-.3.3a3.2,3.2,0,0,0-2.3-.9,3.4,3.4,0,0,0-3.4,3.3,3.3,3.3,0,0,0,3.4,3.3,2.8,2.8,0,0,0,2.3-.9l.3.4a3.4,3.4,0,0,1-2.6,1A3.8,3.8,0,0,1,68.2,107.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M82.3,111.3l-1.8-2.5H77.6v2.5h-.5v-7.5h2.7c1.9,0,3,.9,3,2.5a2.5,2.5,0,0,1-1.8,2.4l1.9,2.6Zm-2.5-3c1.6,0,2.4-.7,2.4-2s-.8-2-2.4-2H77.6v4Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M89.9,109.2H85.6l-.9,2.1h-.6l3.4-7.5h.6l3.4,7.5h-.6Zm-.2-.5-1.9-4.3-2,4.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M93.9,104.3v3.2h4v.5h-4v3.3h-.5v-7.5h5v.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M102.1,104.3H99.4v-.5h6v.5h-2.7v7h-.6Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M106.4,110.3l.2-.4a3.8,3.8,0,0,0,2.5.9c1.5,0,2.1-.6,2.1-1.4,0-2.3-4.6-.9-4.6-3.7,0-1,.8-2,2.6-2a4.5,4.5,0,0,1,2.3.7l-.2.5a3.5,3.5,0,0,0-2.1-.7c-1.5,0-2.1.7-2.1,1.5,0,2.3,4.7.9,4.7,3.7,0,1-.9,1.9-2.7,1.9A3.7,3.7,0,0,1,106.4,110.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M121.2,111.3v-6.4l-3.1,5.4h-.3l-3.1-5.4v6.4h-.6v-7.5h.5l3.3,5.8,3.4-5.8h.4v7.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M129.5,109.2h-4.3l-1,2.1h-.6l3.5-7.5h.5l3.5,7.5h-.6Zm-.2-.5-2-4.3-1.9,4.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M139,103.8v7.5h-.4l-5.1-6.5v6.5h-.6v-7.5h.5l5.1,6.5v-6.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M141.4,110.3l.3-.4a3.5,3.5,0,0,0,2.4.9c1.5,0,2.2-.6,2.2-1.4,0-2.3-4.7-.9-4.7-3.7,0-1,.8-2,2.7-2a4.1,4.1,0,0,1,2.2.7l-.2.5a3.2,3.2,0,0,0-2-.7c-1.5,0-2.1.7-2.1,1.5,0,2.3,4.6.9,4.6,3.7,0,1-.8,1.9-2.7,1.9A3.9,3.9,0,0,1,141.4,110.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M155.3,103.8v7.5h-.6v-3.6h-4.9v3.6h-.6v-7.5h.6v3.4h4.9v-3.4Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M158.4,103.8h.6v7.5h-.6Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M167.8,106.3c0,1.6-1.1,2.5-3,2.5h-2.1v2.5h-.6v-7.5h2.7C166.7,103.8,167.8,104.7,167.8,106.3Zm-.5,0c0-1.3-.9-2-2.5-2h-2.1v4h2.1C166.4,108.3,167.3,107.6,167.3,106.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M172.8,110.3l.3-.4a3.5,3.5,0,0,0,2.4.9c1.5,0,2.2-.6,2.2-1.4,0-2.3-4.7-.9-4.7-3.7,0-1,.8-2,2.7-2a4.1,4.1,0,0,1,2.2.7l-.2.5a3.2,3.2,0,0,0-2-.7c-1.5,0-2.1.7-2.1,1.5,0,2.3,4.6.9,4.6,3.7,0,1-.8,1.9-2.7,1.9A3.7,3.7,0,0,1,172.8,110.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M185.8,110.8v.5h-5.2v-7.5h5v.5h-4.5v2.9h4v.5h-4v3.1Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M189.7,104.3H187v-.5h5.9v.5h-2.6v7h-.6Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M198.1,103.8h.5v7.5h-.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M207.9,103.8v7.5h-.5l-5.1-6.5v6.5h-.5v-7.5h.4l5.1,6.5v-6.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M213.5,110.3l.3-.4a3.5,3.5,0,0,0,2.4.9c1.5,0,2.2-.6,2.2-1.4,0-2.3-4.7-.9-4.7-3.7,0-1,.8-2,2.7-2a3.8,3.8,0,0,1,2.2.7l-.2.5a3.2,3.2,0,0,0-2-.7c-1.5,0-2.1.7-2.1,1.5,0,2.3,4.6.9,4.6,3.7,0,1-.8,1.9-2.7,1.9A3.9,3.9,0,0,1,213.5,110.3Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M222.6,104.3H220v-.5h5.9v.5h-2.7v7h-.6Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M226.9,107.5a3.9,3.9,0,0,1,7.8,0,3.9,3.9,0,0,1-7.8,0Zm7.3,0a3.3,3.3,0,0,0-3.4-3.3,3.3,3.3,0,0,0,0,6.6A3.3,3.3,0,0,0,234.2,107.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M243.2,103.8v7.5h-.4l-5.1-6.5v6.5h-.6v-7.5h.5l5.1,6.5v-6.5Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                        <path d="M251.5,110.8v.5h-5.1v-7.5h5v.5h-4.5v2.9h4v.5h-4v3.1Z" fill="#cfddeb" stroke="#cfddeb" stroke-miterlimit="10" stroke-width="0.31"/>
                    </g>
                    <g>
                        <g>
                            <polygon points="117.8 0.2 128.5 4.1 132.4 7.4 132.1 16.3 126.1 26.7 114.8 28.2 108.9 18.2 108.3 9.2 117.8 0.2" fill="#fff" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                            <polyline points="128.5 4.1 127.7 11.5 132.3 14.7" fill="#fff" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                            <polyline points="108.3 9.2 112.8 15.5 118.4 15.1 127.6 11.6 124.6 19.1 125.9 26.6" fill="#fff" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                            <polyline points="112.8 15.6 113.7 20.9 117.1 23 114.8 28" fill="#fff" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                            <line x1="117.1" y1="23" x2="124.5" y2="19" fill="#fff" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                        </g>
                        <polygon points="160.8 4.3 149.8 1.1 144.8 1.5 139.8 8.9 138.5 20.8 146.8 28.7 157.4 24 163.3 17.1 160.8 4.3" fill="#bebebe"/>
                        <polyline points="149.8 1.1 146.1 7.6 140.5 7.5" fill="none" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                        <polyline points="163.3 17.1 155.8 19.5 151.6 16 146.1 7.8 144.2 15.5 138.7 20.9" fill="none" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                        <polyline points="155.8 19.6 152 23.4 148 23.1 146.9 28.5" fill="none" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                        <line x1="148" y1="23.1" x2="144.3" y2="15.5" fill="none" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="0.42"/>
                    </g>
                </g>
            </g>
        </g>
            </svg>
          </h1>
        </div>
      </header>

      <div class="main-blk">
        <videoSection :testimonialOpen="testimonialOpen" v-on:toggleTestimonial="toggleTestimonial" :menuOpen="menuOpen"
          :videoPlaying="videoPlaying" v-on:toggleVideo="toggleVideo" :mobileScreen="mobileScreen" />
        <mobileMenu v-show="menuOpen == 'nav'" :menuOpen="menuOpen" :furntitureOpen="furntitureOpen"
        v-on:toggleFurniture="toggleFurniture" />
        <infoSection v-show="!mobileScreen && menuOpen =='tel'" :mobileScreen="mobileScreen" />
      </div>

      <infoSection v-if="mobileScreen" />
    </div>

    <div v-if="videoPlaying">
      <videoPage :videoPlaying="videoPlaying" v-on:toggleVideo="toggleVideo" />
    </div>

    <div v-show="furntitureOpen" class="page-container page-container--furniture-page">
      <furniturePage :furntitureOpen="furntitureOpen" v-on:toggleFurniture="toggleFurniture" />
    </div>

  </div>
</template>

<script>
import navMenu from './components/navigationMenu.vue'
import infoSection from './components/infoSection.vue'
import videoSection from './components/videoSection.vue'
import mobileMenu from './components/mobileMenu.vue'
import videoPage from './components/videoPage.vue'
import furniturePage from './components/furniturePage.vue'

export default {
  name: 'App',
  components: {
    navMenu,
    infoSection,
    mobileMenu,
    videoSection,
    videoPage,
    furniturePage
  },
  data: () => ({
    mobileScreen: true,
    menuOpen: 'no',
    furntitureOpen: false,
    testimonialOpen: false,
    videoPlaying: false
  }),
  methods: {
    screenCheck () {
      if (window.innerWidth < 1000) {
        this.mobileScreen = true
      } else {
        this.mobileScreen = false
      }
    },
    toggleMenu () {
      if (this.menuOpen !== 'nav') {
        this.menuOpen = 'nav'
      } else {
        this.menuOpen = 'no'
      }
    },
    toggleCallMenu () {
      if (this.menuOpen !== 'tel') {
        this.menuOpen = 'tel'
      } else {
        this.menuOpen = 'no'
      }
    },
    toggleTestimonial () {
      if (this.testimonialOpen) {
        this.testimonialOpen = false
      } else {
        this.testimonialOpen = true
      }
    },
    toggleVideo () {
      if (this.videoPlaying) {
        this.videoPlaying = false
        history.pushState(null, null, '?')
      } else {
        this.videoPlaying = true
        history.pushState(null, null, '?video')
      }
    },
    toggleFurniture () {
      if (this.furntitureOpen) {
        this.furntitureOpen = false
        history.pushState(null, null, '?')
      } else {
        this.furntitureOpen = true
        history.pushState(null, null, '?furniture')
      }
    },
    backFunction () {
      if (window.location.href.indexOf('video') !== -1) {
        this.videoPlaying = true
      } else if (window.location.href.indexOf('furniture') !== -1) {
        this.furntitureOpen = true
      } else {
        this.videoPlaying = false
        this.furntitureOpen = false
      }
    }
  },
  created () {
    window.addEventListener('resize', this.screenCheck)
    window.addEventListener('orientationchange', this.screenCheck)

    if (window.location.href.indexOf('video') !== -1) {
      this.videoPlaying = true
    }
    if (window.location.href.indexOf('furniture') !== -1) {
      this.furntitureOpen = true
    }
  },
  async mounted () {
    this.screenCheck()
    window.addEventListener('popstate', this.backFunction, false)
  },
  destroyed () {
    window.removeEventListener('resize', this.screenCheck)
    window.removeEventListener('orientationchange', this.screenCheck)
    window.removeEventListener('popstate', this.backFunction)
  }
}
</script>

<style lang="scss">
  @import "base.scss";

  .page-container {

    @include lrg {
      padding: 0 50px;
    }

    header {
      padding: 20px;
      box-sizing: border-box;

      @include lrg {
        @include flex;
        @include flex-justify-between;
        @include flex-align-center;
        padding: 20px 0;
      }

      .right-block {
        @include lrg {
          order: 2;

          .info-section__title {
            color: $color-lightblue;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
            text-align: center;
          }

          .info-section__copy {
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            width: 100%;
            max-width: 250px;
            line-height: 20px;
            margin: 0 auto;
          }
        }
      }

      .header-logo {
        width: 80%;
        max-width: 315px;
        margin: 10px auto 20px;

        @include lrg {
          order: 1;
          margin: 0;
        }

        h1 {
          margin: 0;
        }
      }
    }

    .main-blk {
      position: relative;

      @include med {
        max-width: 90%;
        margin: 0 auto;
      }
    }
  }
</style>
