<template>
  <div class="main-blk__container">
    <div class="video-section">
      <a href="/" title="Watch the video" v-on:click="toggleVideo">
        <picture>
          <source srcset="../assets/video-thumb-sml.jpg" media="(max-width: 500px)" alt="Expertly supplied and fitted luxury marble floor and wall tiles for this wet room, bathroom. Free design service with five star reviews of highly experienced service.">
          <source srcset="../assets/video-thumb-med.jpg" media="(max-width: 1500px)" alt="Expertly supplied and fitted luxury marble floor and wall tiles for this wet room, bathroom. Free design service with five star reviews of highly experienced service.">
          <source srcset="../assets/video-thumb-lrg.jpg" media="(max-width: 5000px)" alt="Expertly supplied and fitted luxury marble floor and wall tiles for this wet room, bathroom. Free design service with five star reviews of highly experienced service.">
          <img src="../assets/video-thumb-sml.jpg" alt="Expertly supplied and fitted luxury marble floor and wall tiles for this wet room, bathroom. Free design service with five star reviews of highly experienced service.">
        </picture>
      </a>
    </div>
    <div class="testimonial" v-show="menuOpen == 'no' && testimonialOpen">
        <p><span class="mid">You will not find anybody better at working with stone.</span>
        <span>Working in complete collaboration, Paolo helped me to achieve my vision by listening to my
          ideas and recommending certain products and finishes.</span>
        <span>Paolo shares my attention to detail and appreciation of great design. Both bathrooms
          were finished to a five-star standard and on budget.</span>
        <span>Tim Dawson OBE</span></p>
    </div>
    <div class="testimonial-link" v-show="menuOpen == 'no'">
      <a href="/" v-on:click="toggleTestimonial" :class="testimonialOpen ? 'showUp' : 'showDown'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 4.2">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
              <polygon points="0 0 11 0 5.7 4.2 0 0" />
            </g>
          </g>
        </svg>
        <span>Our clients feedback</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoSecton',
  props: {
    mobileScreen: Boolean,
    testimonialOpen: Boolean,
    videoPlaying: Boolean,
    menuOpen: String
  },
  methods: {
    toggleTestimonial (e) {
      e.preventDefault()
      this.$emit('toggleTestimonial')
    },
    toggleVideo (e) {
      e.preventDefault()
      this.$emit('toggleVideo')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../base.scss";

  .main-blk__container {
    .video-section {
      picture {
      }
    }
    .testimonial {
      position: absolute;
      background-color: $color-darkblue-opacity;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      @include flex;
      @include flex-justify-center;

      @include med {
        @include flex-align-center;
      }

      @include lrg {
        width: 45%;
        max-width: 480px;
      }

      p {
        text-align: center;
        padding: 0 5px;
        font-size: 9px;
        max-width: 295px;

        @include sml {
          font-size: 13px;
          max-width: 320px;
        }

        @include med {
          font-size: 15px;
          max-width: 450px;
        }

        @include lrg {
          padding: 0 50px;
        }

        span {
          margin: 7px 0;
          display: block;

          @include med {
            margin: 20px 0;
          }

          &.mid {
            color: $color-lightblue;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;

            @include sml {
              font-size: 15px;
            }

            @include med {
              font-size: 17px;
            }
          }
        }
      }
    }
    .testimonial-link {
      position: absolute;
      bottom: 20px;
      right: 20px;

      @include med {
        right: 0;
      }

      a {
        text-decoration: none;
        color: #fff;
        display: block;

        @include med {
          background-color: $color-darkblue-opacity;
          padding: 5px 30px;
        }

        &.showUp {
          @include med {
            background-color: transparent;
          }
        }

        &.showDown {
          svg {
            transform: rotate(180deg);
          }
        }

        svg {
          fill: #fff;
          width: 20px;
          display: block;
          margin: 0 auto 5px;
        }

        span {
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
