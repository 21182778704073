<template>
  <div class="video-player">
    <video autoplay="true" controls>
      <source src="../assets/carrarino-bathroom-downstairs.mp4" type="video/mp4">
      <p>Your browser doesn't support HTML5 video. <a href="../assets/carrarino-bathroom-downstairs.mp4">View the video.</a></p>
    </video>
    <a href="/" class="close-menu" v-on:click="toggleVideo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.8 50.2">
        <title>Close Menu</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_2-2" data-name="Layer 2">
            <g>
              <line x1="2.6" y1="47.6" x2="43.2" y2="2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.24"/>
              <line x1="43.2" y1="47.6" x2="2.6" y2="2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.24"/>
            </g>
          </g>
        </g>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'videoPage',
  props: {
    videoPlaying: Boolean
  },
  methods: {
    toggleVideo (e) {
      e.preventDefault()
      this.$emit('toggleVideo')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../base.scss";

  .video-player {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    top: 0;
    left: 0;
    @include flex;

    video {
      display: block;
      width: 100%;
      max-width: 100vw;
      max-height: 100vh;
    }

    a {
      position: absolute;
      top: 15px;
      right: 15px;

      svg {
        stroke: $color-lightblue;
        width: 25px;
      }

      &:hover {
        svg {
          stroke: #fff;
        }
      }
    }
  }
</style>
